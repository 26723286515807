$color: #e2161a;
// General
.landing {
  color: #283153 !important;
  padding-top: 48px;
}

.title {
  font-weight: 700 !important;
  font-size: 50px !important;
}

.subtitle {
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 44px;
  margin: 0;
}

.description {
  font-weight: 400 !important;
  font-size: 25px !important;
  line-height: 38px;
  margin: 0;
}

.highlight {
  color: #FF6565 !important;
}

.text {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 40px !important;
  margin: 0 !important;
}

.gray {
  background-color: #D8E2EE;
}

.image {
  border-radius: 4px;
}

.hfull {
  min-height: calc(100vh - 400px);
}

// Hero
.hero {
  background-color: #FFFFFF;
}


// Sevices
.iconCheck {
  color: #15CF74;
}

.imgService {
  height: 150px;
}

// About
.about {
  background-color: #FFFFFF;
}

// News
.divider {
  width: 150px;
  color: #FF6565;
  height: 3px;
  margin: 20px 0;
}

.video {
  width: 500px;
  height: 280px;
  border-radius: 8px;
  box-shadow: 0px 10px 40px -12px #283153;
}

.awardImage {
  border-radius: 8px;
  box-shadow: 0px 10px 40px -12px #283153;
}

.kioskList{
  cursor: pointer;

  &:hover {
    background-color: #fff5f5;
  }
}

// Kiosk
.kioskName {
  color: #FF6565;
  text-transform: uppercase;
}

.kioskDesc {
  position: absolute !important;
  bottom: 12px !important;
  // width: 100% !important;
  right: 60px;
  left: 0;
  background-color: #FFFFFF !important;
  padding: 10px 20px !important;
  margin: 10px 10px !important;
  border-radius: 2px;
  box-shadow: 0px 4px 20px -12px #283153;
}

.kioskLocationList {
  position: absolute !important;
  top: 55px !important;
  width: 450px !important;
  background-color: #FFFFFF !important;
  padding: 10px 20px !important;
  margin: 10px 10px !important;
  border-radius: 2px;
  box-shadow: 0px 4px 20px -12px #283153;
}



@media screen and (max-width: 320px) {
  .video {
    width: 300px !important;
    height: 170px !important;
  }

  .title {
    font-size: 25px !important;
  }

  .subtitle {
    font-size: 20px !important;
    line-height: 30px;
  }

  .text {
    font-size: 16px !important;
    line-height: 25px !important;
  }

  .description {
    font-size: 20px !important;
    line-height: 30px;
  }

  .imgService {
    height: 100px;
  }
}

@media screen and (max-width: 480px) {
  .video {
    width: 500px !important;
    height: 260px !important;
  }

  .title {
    font-size: 35px !important;
  }

  .subtitle {
    font-size: 20px !important;
    line-height: 30px;
  }

  .text {
    font-size: 16px !important;
    line-height: 25px !important;
  }

  .description {
    font-size: 20px !important;
    line-height: 30px;
  }

  .imgService {
    height: 100px;
  }
}

@media screen and (max-width: 576px) {
  .sliderParagraph {
    max-height: 150px;
    overflow: scroll;
  }
}

@media screen and (max-width: 768px) {
  .video {
    width: 700px !important;
    height: 580px !important;
  }
}

@media screen and (max-width: 1024px) {
  .video {
    width: 500px;
    height: 260px;
  }
}